<template>
	<div class="container">
		<yi-list-layout :loading="isLoading">
			<template #title>
				<div class="header-title">商品管理</div>
				<div class="header-button">
					<el-button v-permission="'pc/goods/lease/paginate/add'" type="success" @click="onCreate">
						<el-icon class="icon-plus"><Plus /></el-icon>
						新增
					</el-button>
				</div>
			</template>
			<template #filter>
				<yi-filter ref="filterRef" :setting="settingRef" @change="onChangeFilter" />
				<div class="button-list">
					<el-button
						v-for="(button, index) in statusList"
						:key="button.key"
						:class="selectedIndex === index ? 'selected-button' : ''"
						@click="onStatusChange(index, button)"
					>
						{{ button.title }}({{ count[button.key] }})
					</el-button>
				</div>
			</template>
			<template #table>
				<yi-table
					ref="yiTableRef"
					table-height="100%"
					:columns="columns"
					:data="tableData"
					showSelection
					autoWidth
					:params="{ page }"
				>
					<template #status="{ value }">
						<span>{{ mapDict('status', value) }}</span>
					</template>
					<template #carModelList="{ value }">
						<el-tooltip :content="tooltips(value)" placement="top-start">
							<span>{{ format(value, '款车') }}</span>
						</el-tooltip>
					</template>
					<template #companyList="{ value }">
						<el-tooltip :content="tooltips(value)" placement="top-start">
							<span>{{ format(value, '个公司') }}</span>
						</el-tooltip>
					</template>
					<template #platformChannelList="{ value }">
						<el-tooltip :content="tooltips(value)" placement="top-start">
							<span>{{ format(value, '个平台') }}</span>
						</el-tooltip>
					</template>
					<template #operation="{ value, row }">
						<div class="button-list">
							<span
								v-permission="'pc/goods/lease/paginate/putaway'"
								v-if="row.leaseSchemeStatus == 1 && [2, 3].includes(row.status)"
								class="cursor"
								@click="onTurnStatus(1, row.id)"
							>
								上架
							</span>
							<span
								v-permission="'pc/goods/lease/paginate/dismount'"
								v-if="row.leaseSchemeStatus == 1 && row.status == 1"
								class="cursor"
								@click="onTurnStatus(2, row.id)"
							>
								下架
							</span>
							<span
								v-permission="'pc/goods/lease/paginate/failure'"
								v-if="row.leaseSchemeStatus == 1"
								class="cursor"
								@click="onFailure(row)"
							>
								失效
							</span>
							<span
								v-permission="'pc/goods/lease/paginate/edit'"
								v-if="row.leaseSchemeStatus == 2"
								class="cursor"
								@click="onDetail(row, 'edit')"
							>
								编辑
							</span>
							<span class="cursor" @click="onDetail(row, 'detail')">查看</span>
						</div>
					</template>
				</yi-table>
			</template>
			<template #footer>
				<yi-pagination
					:current-page="page.current"
					:page-size="page.size"
					:total-count="totalCount"
					@page-change="onPageChange"
					@size-change="onSizeChange"
				/>
			</template>
		</yi-list-layout>
		<Form ref="formRef" @on-refresh="fetchList" />
	</div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
	name: 'VehicleManagement',
})
</script>

<script setup name="VehicleManagement">
import { inject, onMounted, ref, onActivated } from 'vue'
import { setting, columns } from './config'
import { useState, useFetch, useDict } from '@/utils/hooks.js'
import { companySelect, channelSelect } from '@/api/vehicle.js'
import { ElMessageBox } from 'element-plus'
import { productList, productStatistics, productFailure, productOnOff } from '@/api/product.js'

import Form from './form'
const $message = inject('$message')

const formRef = ref(null)
const settingRef = ref(setting)

const [params, setParams] = useState({})
const [status, setStatus] = useState({ leaseSchemeStatus: null })
const [totalCount, setTotal] = useState(0)
const [tableData, setTable] = useState([])

const { isLoading, fetchData } = useFetch()
const [page, setPage] = useState({ current: 1, size: 20 })
const [selectedIndex, setIndex] = useState(0)
const [count, setCount] = useState({})

const statusList = [
	{ title: '全部', key: 'allNum' },
	{ title: '审批中', key: 'approveUnderwayNum', status: [0] },
	{ title: '生效中', key: 'validNum', status: [1] },
	{ title: '审批拒绝', key: 'approveTurnDownNum', status: [2] },
	{ title: '已失效', key: 'failureNum', status: [3] },
]

async function fetchList(refreshStatistics = true) {
	const payload = { page: page.value, ...params.value, ...status.value }
	const service = productList
	const { data, err } = await fetchData(service, payload)
	if (err) {
		$message.error(err.message)
	} else {
		if (refreshStatistics) fetchStat()
		setTable(data.records)
		setTotal(data.total)
	}
}
function format(val, tip) {
	if (Array.isArray(val) && val.length) {
		let txt = val[0].businessName
		if (val.length > 1) txt += ` 等${val.length - 1}${tip}`
		return txt
	}
	return '--'
}
function tooltips(val) {
	if (Array.isArray(val) && val.length) {
		return val.map(item => item.businessName).join('、')
	}
	return ''
}
async function fetchStat() {
	const payload = { page: page.value, ...params.value }
	const { data, err } = await fetchData(productStatistics, payload)
	console.log('data', data)
	if (err) {
		$message.error(err.message)
	} else {
		setCount(data)
	}
}
function onDetail(row, type) {
	formRef.value.open(row.id, type)
}
function onFailure(row) {
	ElMessageBox.confirm(`确定失效该条商品?`, '提示', {
		confirmButtonText: '确认',
		cancelButtonText: '取消',
		type: 'warning',
	})
		.then(async () => {
			const { err } = await fetchData(productFailure, row.id)
			if (err) return $message.error(err.message)
			$message.success('操作成功')
			fetchList()
		})
		.catch(() => {})
}
function onTurnStatus(status, id) {
	const action = { 1: '上架', 2: '下架' }[status]
	ElMessageBox.confirm(`确定${action}该条商品?`, '提示', {
		confirmButtonText: '确认',
		cancelButtonText: '取消',
		type: 'warning',
	})
		.then(async () => {
			const { err } = await fetchData(productOnOff, { id, status })
			if (err) return $message.error(err.message)
			$message.success('操作成功')
			fetchList()
		})
		.catch(() => {})
}
function onStatusChange(index, button) {
	setIndex(index)
	// eslint-disable-next-line no-prototype-builtins
	if (button.hasOwnProperty('status')) {
		setStatus({ leaseSchemeStatus: button.status })
	} else {
		setStatus({ leaseSchemeStatus: null })
	}
	fetchList()
}
function mapData(list, labelValue = 'name', value = 'id') {
	return list.map(item => ({ label: item[labelValue], value: item[value] }))
}
function mapSelect(list, key = 'name', value = 'id') {
	return list.map(item => ({ name: item[key], code: item[value] }))
}
function mapDict(key, value) {}
async function fetchDict() {
	const dict = await useDict([
		'fuel_type',
		'annual_audit_status',
		'goods_lease_status',
		'commercial_insurance_status',
		'compulsory_insurance_status',
	])
	console.log('dict', dict)
	const status = findItem('status')

	status.options = dict.goods_lease_status
}
async function fetchSelect() {
	const [companyList, channelList] = await Promise.all([
		companySelect({ isPermission: true }),
		channelSelect({ isPermission: true }),
	])
	const list = {}

	if (companyList.code === 200)
		list.companyList = mapData(companyList.data, 'companyId', 'companyName')
	if (channelList.code === 200) list.channelList = mapData(channelList.data)

	const channel = findItem('platformChannelIds')
	const company = findItem('companyIds')

	channel.options = mapSelect(channelList.data)
	company.options = mapSelect(companyList.data, 'companyName', 'companyId')
}

function findItem(key) {
	return settingRef.value.filters.find(item => item.attr === key)
}

function onSizeChange(size) {
	setPage({ current: 1, size })
	fetchList()
}
function onPageChange(current) {
	const { size } = page.value
	setPage({ current, size })
	fetchList()
}

function onChangeFilter(event) {
	const date = {}
	;['createTime', 'clivtaDate', 'carInsuranceDate', 'annualAuditDate'].forEach(key => {
		const data = event[key]
		if (Array.isArray(data) && data.length) {
			const [start, end] = data
			date[`${key}Start`] = start
			date[`${key}End`] = end
		} else {
			delete params.value[`${key}Start`]
			delete params.value[`${key}End`]
		}
	})
	console.log('event', event)

	const payload = { ...params.value, ...date, ...event }
	if (![null, undefined, ''].includes(event.status)) {
		payload.status = [event.status]
	}
	setParams(payload)
	fetchList()
}
function onCreate() {
	formRef.value.open()
}
onActivated(() => {
	fetchList()
})
onMounted(() => {
	fetchSelect()
	fetchDict()
	fetchList()

	fetchStat()
})
</script>

<style lang="less" scoped>
.container {
	width: 100%;
	height: 100%;
	overflow: hidden;
	.header-title {
		height: 54px;
		line-height: 54px;
		overflow: hidden;
		float: left;
	}
	.header-button {
		margin: 11px 0;
		float: right;
	}
	.selected-button {
		border: 1px solid #409eff;
		color: #409eff;
	}
	.cursor {
		cursor: pointer;
		color: #409eff;
	}
}
.button-list {
	padding: 0 !important;
	margin-top: 10px;
	span {
		margin-left: 5px;
	}
}
</style>
