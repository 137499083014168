export const columns = [
	{
		prop: 'leaseNo',
		label: '商品ID',
		width: '140px',
	},
	{
		prop: 'statusName',
		label: '商品状态',
		width: '100px',
	},
	{
		prop: 'leaseSchemeName',
		label: '商品名称',
		width: '230px',
	},

	{
		prop: 'periodsNumStr',
		label: '期数',
	},
	{
		prop: 'monthlyAmountStr',
		label: '月租',
		width: '160px',
	},
	{
		prop: 'cashPledgeAmountStr',
		label: '押金',
		width: '160px',
	},
	{
		prop: 'leaseSchemeStatusName',
		label: '方案状态',
		width: '140px',
	},
	{
		prop: 'carModelList',
		label: '支持车型',
		width: '300px',
	},
	{
		prop: 'companyList',
		label: '所属公司',
		width: '300px',
	},
	{
		prop: 'platformChannelList',
		label: '平台渠道',
		width: '300px',
	},
	{
		prop: 'validStartTime',
		label: '生效时间',
		width: '230px',
	},
	{
		prop: 'validEndTime',
		label: '失效时间',
		width: '230px',
	},
	{
		prop: 'createTime',
		label: '创建时间',
		width: '230px',
	},
	{
		prop: 'createUserName',
		label: '创建人',
		width: '140px',
	},
	{
		prop: 'operation',
		label: '操作',
		width: '190px',
		fixed: 'right',
	},
]
export const setting = {
	expand: true,
	filters: [
		{
			placeholder: '请输入商品ID',
			label: '商品ID',
			attr: 'leaseNo',
			type: 'search',
		},
		{
			placeholder: '请输入商品名称',
			label: '商品名称',
			attr: 'leaseSchemeName',
			type: 'search',
		},
		{
			placeholder: '请输入车辆信息',
			label: '车辆信息',
			attr: 'carModelName',
			type: 'search',
		},
		{
			placeholder: '请选择商品状态',
			label: '商品状态',
			attr: 'status',
			type: 'select',
			options: [],
		},
		{
			placeholder: '请选择所属公司',
			label: '所属公司',
			attr: 'companyIds',
			type: 'multi-select',
			width: 320,
			options: [],
		},
		{
			placeholder: '请选择平台渠道',
			label: '平台渠道',
			attr: 'platformChannelIds',
			type: 'multi-select',
			options: [],
		},
	],
}
