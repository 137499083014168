import request from '@/http'

// 商品管理列表
export function productList(data) {
	return request({
		url: '/suf4-operation-service/pc/goods/lease/paginate',
		method: 'post',
		data: {
			page: {
				current:1,
				size:50
			},
			...data
		},
	})
}

// 商品管理统计
export function productStatistics(data) {
	return request({
		url: '/suf4-operation-service/pc/goods/lease/statistics',
		method: 'post',
		data,
	})
}

// 创建商品
export function createProduct(data) {
	return request({
		url: '/suf4-operation-service/pc/goods/lease/create',
		method: 'post',
		data,
	})
}

// 编辑商品
export function updateProduct(data) {
	return request({
		url: '/suf4-operation-service/pc/goods/lease/update',
		method: 'post',
		data,
	})
}

// 商品详情
export function productDetail(id) {
	return request({
		url: `/suf4-operation-service/pc/goods/lease/getById/${id}`,
		method: 'get',
	})
}
// 商品失效
export function productFailure(id) {
	return request({
		url: `/suf4-operation-service/pc/goods/lease/failure/${id}`,
		method: 'get',
	})
}
// 商品上下架
export function productOnOff(data) {
	return request({
		url: `/suf4-operation-service/pc/goods/lease/on/off`,
		method: 'post',
		data,
	})
}

// 通过合同ID获取到公司福利政策信息
export function getCompanyPolicy(id) {
	return request({
		url: `/suf4-asset-service/order/info/company/welfare/policy?orderId=${id}`,
		method: 'get',
	})
}
