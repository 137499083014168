export const form = [
	{
		prop: 'leaseSchemeName',
		label: '租赁方案',
		required: true,
		component: 'el-input',
		attrs: { placeholder: '请输入租赁方案', maxlength: 15 },
	},
	{
		prop: 'leaseSchemeType',
		label: '方案类型',
		required: true,
		custom: true,
		attrs: { placeholder: '请选择方案类型' },
	},
	{
		prop: 'carModelList',
		label: '支持车型',
		required: true,
		custom: true,
		attrs: { placeholder: '请选择车辆车型' },
	},
	{
		prop: 'companyList',
		label: '所属公司',
		required: true,
		component: 'el-select',
		cWidth: 500,
		attrs: {
			placeholder: '请选择所属公司',
			multiple: true,
			'collapse-tags': true,
			filterable: true,
		},
	},
	{
		prop: 'platformChannelList',
		label: '平台渠道',
		required: true,
		component: 'el-select',
		cWidth: 500,
		attrs: {
			placeholder: '请选择平台渠道',
			multiple: true,
			'collapse-tags': true,
			filterable: true,
		},
	},
	{
		prop: 'validTime',
		label: '方案有效期',
		required: true,
		custom: true,
		attrs: { placeholder: '请选择方案有效期' },
	},
	{
		prop: 'imgUrlList',
		label: '商品图片',
		// required: true,
		custom: true,
		attrs: { placeholder: '请上传商品图片' },
	},
	{
		prop: 'shelfWay',
		label: '上架方式',
		required: true,
		custom: true,
		attrs: { placeholder: '请选择上架方式' },
	},
]
export const input = [
	{
		prop: 'monthlyAmount',
		label: '月租',
		required: true,
		component: 'el-input',
		attrs: { maxLength: 5, placeholder: '请输入月租金额' },
	},
	{
		prop: 'periodsNum',
		label: '期数',
		required: true,
		component: 'el-input',
		attrs: { placeholder: '请输入期数', maxLength: 2 },
	},
	{
		prop: 'cashPledgeAmount',
		label: '押金',
		required: true,
		component: 'el-input',
		attrs: { maxLength: 5, placeholder: '请输入押金金额' },
	},
	{
		prop: 'cashPledgeWay',
		label: '押金方式',
		component: 'el-input',
		attrs: { placeholder: '请输入押金方式', type: 'textarea' },
	},
	{
		prop: 'liquidatedDamagesRatio',
		label: '提前退车违约金',
		required: true,
		custom: true,
		component: 'el-input',
		attrs: { placeholder: '请输入提前退车违约金', maxLength: 6 },
	},
	{
		prop: 'giftDays',
		label: '赠送天数',
		component: 'el-input',
		attrs: { maxLength: 3, placeholder: '请输入赠送天数' },
	},
	{
		prop: 'platformWelfarePolicy',
		label: '平台福利政策',
		component: 'el-input',
		attrs: { maxLength: 30, placeholder: '请输入平台福利政策' },
	},
	{
		prop: 'companyWelfarePolicy',
		label: '公司福利政策',
		custom: true,
	},
]
