<template>
	<el-dialog
		v-model="visible"
		width="750px"
		class="container"
		:title="title"
		:before-close="onClose"
		:close-on-click-modal="false"
		append-to-body
		destroy-on-close
	>
		<div v-loading="isLoading" id="print">
			<yi-form
				:model="model"
				:rules="rules"
				ref="formRef"
				label-width="120px"
				size="small"
				:form-list="formList"
			>
				<template #carModelList>
					<el-select
						size="default"
						style="width: 500px"
						placeholder="请选择车辆车型"
						remote
						multiple
						collapse-tags
						:disabled="disabled"
						v-model="model.carModelList"
						:remote-method="onModelInput"
						@change="onModelChange"
						filterable
					>
						<el-option
							v-for="item in options"
							:key="item.value"
							:label="item.label"
							:value="item.value"
						></el-option>
					</el-select>
				</template>
				<template #leaseSchemeType>
					<el-radio-group v-model="model.leaseSchemeType" :disabled="disabled" class="ml-4">
						<el-radio :label="1">新签</el-radio>
						<el-radio :label="2">续签</el-radio>
					</el-radio-group>
				</template>
				<template #validTime>
					<el-date-picker
						type="daterange"
						size="default"
						:disabled="disabled"
						start-placeholder="请选择开始日期"
						end-placeholder="请选择结束日期"
						style="width: 480px"
						v-model="model.validTime"
					/>
				</template>
				<template #shelfWay>
					<el-radio-group v-model="model.shelfWay" class="ml-4" :disabled="disabled">
						<el-radio :label="1">自动</el-radio>
						<el-radio :label="2">手动</el-radio>
					</el-radio-group>
				</template>
				<template #imgUrlList>
					<span style="width: 500px">({{ model.imgUrlList ? model.imgUrlList.length : 0 }}/5)</span>
					<yi-file-uploader
						v-model="model.imgUrlList"
						:disabled="disabled"
						type="file"
						source-type="image"
						product-type="renrenjia"
						accept=".jpg,.jpeg,.png"
						:max="5"
						:uploader-size="20"
					/>
					<el-checkbox :disabled="disabled" v-model="model.useModelImg">使用车型图片</el-checkbox>
				</template>
			</yi-form>
			<div class="form-container" v-for="(item, index) in model.leaseSchemeList" :key="index">
				<div class="form-title">
					<p style="margin-left: 12px; font-size: 15px; font-weight: 600">方案{{ index + 1 }}</p>
					<div>
						<span
							class="cursor"
							v-if="model.leaseSchemeList.length !== 1 && !disabled"
							style="margin-right: 12px"
							@click="onDelete(index)"
						>
							删除方案
						</span>
						<span
							class="cursor"
							v-if="index == model.leaseSchemeList.length - 1 && !disabled"
							@click="onAdd"
						>
							新增方案
						</span>
					</div>
				</div>
				<yi-form
					:model="item"
					:rules="rules"
					ref="inputRef"
					label-width="120px"
					size="small"
					:form-list="inputForm"
				>
					<template #liquidatedDamagesRatio>
						<div style="display: flex; flex-direction: row; align-items: center">
							<el-input
								placeholder="请输入提前退车违约金"
								v-model="item.liquidatedDamagesRatio"
								size="default"
								:disabled="disabled"
							/>
							<span style="margin-left: 5px; font-size: 15px">%</span>
						</div>
					</template>
					<template #companyWelfarePolicy>
						<div
							style="
								margin-bottom: 10px;
								width: 500px;
								display: flex;
								flex-direction: row;
								align-items: center;
							"
							v-for="(_, i) in item.companyWelfarePolicy"
							:key="i"
						>
							<el-input
								style="width: 420px"
								placeholder="请输入公司福利政策"
								size="default"
								:disabled="disabled"
								v-model="item.companyWelfarePolicy[i]"
							/>
							<span
								class="cursor"
								style="margin-left: 12px"
								v-if="item.companyWelfarePolicy.length > 1 && !disabled"
								@click="onDeletePolicy(index, i)"
							>
								删除
							</span>
							<span
								class="cursor"
								style="margin-left: 12px"
								v-if="i == item.companyWelfarePolicy.length - 1 && !disabled"
								@click="onAddPolicy(index)"
							>
								新增
							</span>
						</div>
					</template>
				</yi-form>
			</div>
		</div>

		<template #footer>
			<div class="dialog-footer" v-if="!disabled">
				<el-button type="primary" @click="onSubmit">确定</el-button>
				<el-button @click="onClose">取消</el-button>
			</div>
		</template>
	</el-dialog>
</template>

<script setup>
import { defineExpose, ref, inject, defineEmits, watch } from 'vue'
import convert from 'dom-to-image'
import request from '@/http'
import axios from 'axios'
import { dataURLtoFile } from '@/utils/util'
import { useState, useModal, useFetch, useSelect } from '@/utils/hooks.js'
import dayjs from 'dayjs'
import { getStore } from '@/utils/store'
import { form, input } from './config'

import { carSelectList, searchModel, companySelect, channelSelect } from '@/api/vehicle'
import { createProduct, productDetail, updateProduct } from '@/api/product'

const $message = inject('$message')
const emit = defineEmits(['on-refresh', 'on-delivery'])
const formList = ref(form)
const { visible, title, setVisible, setTitle } = useModal()
const { isLoading, fetchData } = useFetch()
const [model, setModel] = useState({
	useModelImg: true,
	leaseSchemeList: [{ companyWelfarePolicy: [''] }],
})

const [options, setOptions] = useState([])
const [disabled, setDisabled] = useState(false)
const inputForm = ref(input)
const rules = {
	carModelList: [{ required: true, trigger: 'change', message: '请选择车辆车型' }],
	companyList: [{ required: true, trigger: 'change', message: '请选择所属公司' }],
	platformChannelList: [{ required: true, trigger: 'change', message: '请选择平台渠道' }],
	shelfWay: [{ required: true, trigger: 'change', message: '请选择上架方式' }],
	leaseSchemeType: [{ required: true, trigger: 'change', message: '请选择方案类型' }],
	monthlyAmount: [{ pattern: /^[1-9]\d{0,4}$/, trigger: 'blur', message: '月租必须是大于0的整数' }],
	periodsNum: [{ pattern: /^[1-9]\d{0,1}$/, trigger: 'blur', message: '期数必须是整数' }],
	cashPledgeAmount: [{ pattern: /^[0-9]\d{0,4}$/, trigger: 'blur', message: '押金必须是整数' }],
	giftDays: [{ pattern: /^[0-9]\d{0,2}$/, trigger: 'blur', message: '赠送天数必须是整数' }],
	liquidatedDamagesRatio: [
		{
			pattern: /^(100(\.0{1,2})?|[1-9]?\d(\.\d{1,2})?|0(\.\d{1,2})?)$/,
			trigger: 'blur',
			message: '必须是0-100之间最多2位小数',
		},
	],
}
const formRef = ref(null)
const inputRef = ref(null)
const userInfo = getStore({ name: 'userInfo' })

watch(
	() => model.value.leaseSchemeType,
	val => {
		const cashPledgeAmount = inputForm.value.find(item => item.prop === 'cashPledgeAmount')
		const cashPledgeWay = inputForm.value.find(item => item.prop === 'cashPledgeWay')
		cashPledgeAmount.hide = val == 2
		cashPledgeWay.hide = val == 2
	},
)
watch(
	() => model.value.inspectResult,
	val => {
		const reason = inputForm.value.find(item => item.prop === 'maintainReason')
		reason.required = !!val
		inputRef.value.clearValidate()
	},
)
async function onModelInput(key = '') {
	const res = await searchModel({ key })
	if (res.code === 200) {
		const data = res.data.map(item => ({ label: item.aggregationColumn, value: item.id }))
		setOptions(data)
	}
}
function onModelChange(a, b, c) {
	console.log('onModelChange', a, b, c)
}
async function fetchChannel(companyId) {
	const { err, data } = await useSelect(() => channelSelect({ companyId, status: 1 }))
	if (err) return console.log('err', err)
	const target = findItem('platformChannelList')
	target.options = data
}
async function fetchSelect() {
	fetchChannel()
}

function onDelete(index) {
	model.value.leaseSchemeList.splice(index, 1)
}
function onAdd() {
	const last = model.value.leaseSchemeList[model.value.leaseSchemeList.length - 1]
	const {
		cashPledgeAmount,
		cashPledgeWay,
		liquidatedDamagesRatio,
		platformWelfarePolicy,
		companyWelfarePolicy,
	} = last
	model.value.leaseSchemeList.push({
		cashPledgeAmount,
		cashPledgeWay,
		liquidatedDamagesRatio,
		platformWelfarePolicy,
		companyWelfarePolicy: [...companyWelfarePolicy],
	})
}

function onDeletePolicy(index, i) {
	model.value.leaseSchemeList[index].companyWelfarePolicy.splice(i, 1)
}
function onAddPolicy(i) {
	model.value.leaseSchemeList[i].companyWelfarePolicy.push('')
}

function open(id, type) {
	fetchList()
	formList.value.forEach(item => {
		if (item.attrs) item.attrs.disabled = type == 'detail'
		else item.attrs = { disabled: type == 'detail' }
	})
	inputForm.value.forEach(item => {
		if (item.attrs) item.attrs.disabled = type == 'detail'
		else item.attrs = { disabled: type == 'detail' }
	})
	if (id) {
		if (type == 'detail') {
			setDisabled(true)
			fetchDetail(id)
			setTitle('商品租赁方案详情')
			setVisible(true)
			return
		}
		setDisabled(false)
		fetchDetail(id)

		setTitle('编辑商品租赁方案')
	} else {
		setDisabled(false)
		setTitle('新增商品租赁方案')
	}

	setVisible(true)
	setTimeout(() => {
		formRef.value.clearValidate()
	}, 0)
}

function onClose() {
	setVisible(false)
	setModel({ useModelImg: true, leaseSchemeList: [{ companyWelfarePolicy: [''] }] })
}

async function fetchDetail(id) {
	const { err, data } = await fetchData(productDetail, id)
	if (err) return $message.error(err.message)
	const {
		validEndTime,
		validStartTime,
		carModelList,
		companyList,
		platformChannelList,
		leaseSchemeList,
		useModelImg,
	} = data
	data.useModelImg = useModelImg ? true : false
	data.validTime = [validStartTime, validEndTime]
	data.carModelList = carModelList.map(item => item.businessId)
	data.companyList = companyList.map(item => item.businessId)
	data.platformChannelList = platformChannelList.map(item => item.businessId)
	data.leaseSchemeList = leaseSchemeList.map(item => ({
		...item,
		companyWelfarePolicy:
			Array.isArray(item.companyWelfarePolicy) && item.companyWelfarePolicy.length
				? item.companyWelfarePolicy
				: [''],
	}))
	setModel(data)
}
async function onSubmit() {
	const refList = () => inputRef.value.map(item => item.validate())
	await Promise.all([formRef.value.validate(), ...refList()])
	const canvas = document.querySelector('#print')
	let url = ''
	isLoading.value = true
	convert.toJpeg(canvas, { bgcolor: '#fff' }).then(curUrl => {
		url = curUrl
		const QINIU_UPLOAD_URL = {
			'oss-test-vsc-master.renrenjia.com.cn': 'https://up-z2.qiniup.com',
			'oss-prod-vsc-master.renrenjia.com.cn': 'https://up-z2.qiniup.com',
			'yiautos-business-oss-1.yiautos.com': 'https://up-z2.qiniup.com',
			'yiautos-business-oss-2.yiautos.com': 'https://up.qiniup.com', // 备用上传地址
		}
		const isProd = process.env.VUE_APP_CONFIG_ENV === 'prod'
		const qiniuCdnPrefix = isProd
			? 'oss-prod-vsc-master.renrenjia.com.cn'
			: 'oss-test-vsc-master.renrenjia.com.cn'
		const bucket_name = isProd ? 'oss-prod-vsc-master' : 'oss-test-vsc-master'
		request({
			url: 'suf4-system-service/oss/getQiniuToken',
			method: 'GET',
			params: {
				bucket_name,
			},
		})
			.then(async tokenRes => {
				if (tokenRes.code === 200) {
					console.log('key', tokenRes.data)
					const str = Math.random().toString(36).substr(2, 8)
					const tenantId = getStore({ name: 'tenantId' }) || '100000'
					const uploadKey = `${tenantId}/renrenjia/image/${dayjs().format('YYYYMMDD')}/${str}.jpg`
					const file = dataURLtoFile(url, str)
					const param = new FormData()
					param.append('token', tokenRes.data)
					param.append('key', uploadKey)
					param.append('file', file)
					const config = {
						headers: { 'Content-Type': 'multipart/form-data' },
					}
					const uploadResult = await axios.post(QINIU_UPLOAD_URL[qiniuCdnPrefix], param, config)
					if (uploadResult.status !== 200) {
						return $message.error('上传商品方案失败!请稍后再试')
					}
					const leaseImgUrl = `https://${qiniuCdnPrefix}/${uploadResult.data.key}`
					const company = findItem('companyList')
					const channel = findItem('platformChannelList')
					const {
						validTime,
						carModelList,
						companyList,
						platformChannelList,
						leaseSchemeList,
						useModelImg,
						...params
					} = model.value
					params.useModelImg = useModelImg ? 1 : 0
					params.validStartTime = dayjs(validTime[0]).format('YYYY-MM-DD') + ' 00:00:00'
					params.validEndTime = dayjs(validTime[1]).format('YYYY-MM-DD') + ' 23:59:59'
					const modelName = (id, list) => {
						const target = list.find(item => item.value === id)
						return target.label
					}
					params.carModelList = carModelList.map(businessId => ({
						businessId,
						businessName: modelName(businessId, options.value),
					}))
					params.companyList = companyList.map(businessId => ({
						businessId,
						businessName: modelName(businessId, company.options),
					}))
					params.platformChannelList = platformChannelList.map(businessId => ({
						businessId,
						businessName: modelName(businessId, channel.options),
					}))
					params.leaseSchemeList = leaseSchemeList.map(item => ({
						...item,
						companyWelfarePolicy: item.companyWelfarePolicy.filter(Boolean) || [],
					}))
					params.leaseImgUrl = leaseImgUrl
					const service = title.value.includes('编辑') ? updateProduct : createProduct
					const { err } = await fetchData(service, params)
					if (err) return $message.error(err.message)
					$message.success('操作成功')
					emit('on-refresh')
					onClose()
				} else {
					isLoading.value = false
					throw new Error(tokenRes.msg)
				}
			})
			.catch(e => {
				$message.error(e)
				isLoading.value = false
			})
	})
}

function mapStatus() {}
function findItem(key) {
	return formList.value.find(item => item.prop === key)
}

async function fetchList() {
	const res = await Promise.all([
		useSelect(() => companySelect(true, { status: 1 }), { name: 'companyName', code: 'companyId' }),
		fetchData(carSelectList, { statusList: [1, 2, 3, 4, 5, 6, 7, 8] }),
	])
	const [companyRes, carRes] = res
	if (carRes.code === 200) {
		const options = carRes.data.map(item => ({
			...item,
			label: item.licensePlateNum,
			value: item.id,
			disabled: ![7, 6].includes(item.status),
			statusName: mapStatus(item.status),
		}))
		const valid = []
		const invalid = []
		for (const option of options) {
			if ([7, 6].includes(option.status)) valid.push(option)
			else invalid.push(option)
		}
		setOptions(valid.concat(invalid))
		if (title.value.includes('新增')) {
			model.value.createUserName = userInfo.userName
		}
	}
	if (companyRes.data) {
		const company = findItem('companyList')
		company.options = companyRes.data
	}
}
onModelInput()
fetchSelect()
defineExpose({ open })
</script>
<style lang="less">
.el-select-v2__placeholder {
	font-weight: 400 !important;
}
.el-select-dropdown__option-item {
	font-size: 14px !important;
}
</style>
<style lang="less" scoped>
.title {
	border-left: 3px solid #2e3443;
	color: #2e3443;
	padding-left: 4px;
	font-size: 16px;
	font-weight: 500;
}
.result-form {
	.flexible(row, center, space-between);
	margin: 20px 0;
}
.cell {
	height: 100%;
	width: 100%;
	.flexible(column, center, center);
}
.button-list {
	.flexible(row, center, space-between);
}
.form-container {
	border: 1px solid #eee;
	margin-bottom: 12px;
	border-radius: 4px;
}
.form-title {
	width: 620px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}
.cursor {
	cursor: pointer;
	color: #409eff;
}
.custom-option {
	.flexible(row, center, space-between);
}
</style>
